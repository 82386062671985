<template>
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <form v-on:submit.prevent="submitForm()">
        <div class="my-1">
          <div class="input-group">
            <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-user" /></div>
            <input
              type="password"
              class="form-control"
              placeholder="новый пароль"
              name="password"
              v-model="form.password" />
          </div>
          <div data-error ref="error-password" class="alert alert-danger my-1 p-1"></div>
        </div>

        <div class="my-1">
          <div class="input-group">
            <div class="input-group-text"><font-awesome-icon icon="fa-solid fa-user" /></div>
            <input
              type="password"
              class="form-control"
              placeholder="новый ещё раз"
              name="password_confirmation"
              v-model="form.password_confirmation" />
          </div>
          <div data-error ref="error-password_confirmation" class="alert alert-danger my-1 p-1"></div>
        </div>

        <div data-error ref="error-common" class="alert alert-info mb-1 mt-3 p-1"></div>

        <div class="my-1">
          <button type="submit" class="btn btn-primary">
            <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon> Сохранить
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faCheck, faUser } from '@fortawesome/free-solid-svg-icons';
library.add(faCheck, faUser);

export default {
  data: function () {
    return {
      form: {
        _token: this.csrf,
        hash: '',
        password: '',
        password_confirmation: '',
      },
      isLoading: false,
    };
  },
  props: {
    csrf: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const searchURL = new URL(window.location);
    this.form.hash = searchURL.searchParams.get('hash');
  },
  components: {
    FontAwesomeIcon,
  },
  methods: {
    submitForm() {
      this.isLoading = true;

      Object.keys(this.$refs).forEach((key) => {
        this.$refs[key].textContent = '';
      });

      axios
        .post(`/evocms-user/reset-password`, this.form)
        .then((res) => {
          if (res.data.status == 'ok') {
            this.$refs[`error-common`].textContent = 'Новый пароль успешно сохранён.';

            this.form.password = '';
            this.form.password_confirmation = '';
            this.form.hash = '';
          }
          if (res.data.status == 'error') {
            const errors = res.data.errors || {};
            const fieldErrors = errors.validation || {};
            const customErrors = errors.customErrors || {};
            const commonErrors = errors.common || [];

            Object.keys(fieldErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = fieldErrors[key].join(' ');
            });

            Object.keys(customErrors).forEach((key) => {
              this.$refs[`error-${key}`].textContent = customErrors[key].join(' ');
            });

            this.$refs[`error-common`].textContent = commonErrors.join(' ');
          }
        })
        .catch((err) => {
          this.$refs[`error-common`].textContent = 'Ошибка на сервере, обновите страницу и попробуйте ещё раз.';
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
